<template>
  <Container wide>
    <h1 class="text-custom-gray-900 font-extrabold text-2xl z-30 flex items-center">
      Takmontage
    </h1>
    <p class="mb-4">
      Windon har tagit fram ett unikt takmontage-material för alla taktyper. Vi har fokuserat på att utveckla ett material som ska vara enkelt för montören att montera samtidigt som det är extremt stabilt och har funktioner som underlättar att följa Boverket, elsäkerhetsverket och försäkringsbolagens krav på installation. Materialet monteras på ca halva tiden jämfört med andra system och är certifierat för Boverkets svenska krav på snölast och vindlast. Materialet i infästningarna är i varmförzinkad stålplåt och är ytbehandlat med UV-beständig pulverlack, profiler och skarvar är i aluminium. Samtliga infästningar är utformade för att minimera åverkan på takmaterialet och det krävs ingen urfräsning av pannor vid montage.
    </p>

    <p class="mb-4">
      Allt infästningsmaterial är konstruerat av Windon AB och allt material är 100% tillverkat i Sverige. Då vi har ökat hållfastheten i materialet med smartare uppbyggnad har det gett oss samma hållfasthet, men med färre takinfästningar. Materialet har en prislapp som motsvarar konkurrenterna samtidigt som montagetiden i många fall halveras. Allt infästningsmaterial har korrusionsklass C4 och klarar av att installeras kustnära. Vid leverans så följer samtliga skruvar och beslag som behövs och för kännedom Windon säljer också verktyg för att klara av installationen.
    </p>

    <p class="mb-4">
      Alla infästningar som består av flera delar har färdiga strutgängade hål, detta gör att montaget går snabbare och man slipper att jobba med lösa muttrar och brickor vid installation. Det behövs inte heller något verktyg för att hålla emot vid montage, utan allt montage kan utföras med skruvdragare. Detta ihop med färre infästningar kommer minska montagetiden avsevärt.
    </p>

    <p class="mb-4">
      Alla infästningar innehåller profilstopp så att profilerna ligger på sin plats innan dom skruvats fast, dessutom kan man välja att montera modulen stående eller liggande utan att behöva några extra tillbehör eller vinklar. Inbyggd kabelkanal i profilen och medföljande kabelklipps möjliggör en korrekt installation utan att behöva hitta på egna lösningar för att fästa kablaget i profilen. Profilen har också en ränna där man kan fästa vår unika krokodilstripes för t.ex. fastsättning av återledare eller kabelslang. Dessa stripes med krokodilklämma passar också vår modulram.
    </p>

    <p class="mb-4">
      Profilen är utformad med bred bas och är 69mm hög istället för traditionella 20-40mm, denna höjd + höjden på infästningar gör att panelen kommer upp från taket och möjliggör ett självdrag under modulen vilket kyler modulen maximalt under drift, samt att det minskar brandrisk vid eventuell hotspott eller brand i kopplingsbox. Generellt ger detta också ett högre effektuttag från modulen och därmed en årsproduktion som är 4-6% högre än en lågt byggd profil. I tillägg går också profilen att skjuta ovanpå varandra vilket nästan dubblar höjden, detta kan vara användbart när man vill höja upp ena profilen för att få lite mer vinkel på modulen. Skarvarna levereras oftast 1st / skarv men kan monteras 2 st vid dom tillfällen där man vill öka hållfastheten. All stålplåt är pulverlackad för att undvika galvaniska vandringar och samtliga skruvar som förbinder infästningar och profiler är i syrafast rostfritt.
    </p>

    <h3 class="text-custom-gray-900 mb-4 font-extrabold text-2xl z-30 flex items-center">
      Dokument
    </h3>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Profil, skarv, kanallock och modulklämmor</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Profil, skarv, kanallock och modulklämmor - Rapport</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Övrig kabeldragning på taket</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Spontfäste och tegelpanna-fäste</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Spontfäste och tegelpanna-fäste - Rapport</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Läktfäste och tegelpanna-fäste</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Läktfäste och tegelpanna-fäste - Rapport</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Spontfäste och Betongpanne-fäste</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Spontfäste och Betongpanne-fäste - Rapport</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Läktfäste och betongpanna-fäste</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Läktfäste och betongpanna-fäste - Rapport</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>TRP-fäste</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>TRP-fäste - Rapport</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Stockskruv-fäste</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Stockskruv-fäste - Rapport</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Plåtfals-fäste</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Plåtfals-fäste - Rapport</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Pappfäste</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Pappfäste - Rapport</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Tillbehör, Kabelskena</div>
    </DownloadButton>
    <DownloadButton class="mb-2">
      <Icon
        name="file"
        class="text-xl mr-2"
      /><div>Tillbehör, täcksida profil</div>
    </DownloadButton>
  </Container>
</template>

<script>

export default {
  name: 'Assmenblies',
  props: {
  }
}
</script>
