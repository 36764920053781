import usersRepository from './real/users'
import newsRepository from './real/news'
import contactRepository from './real/contact'

const repositories = {
  contact: contactRepository,
  news: newsRepository,
  users: usersRepository,
}

export const RepositoryFactory = {
  get: (name) => repositories[name]
}

export default RepositoryFactory
