<template>
  <div class="bg-custom-blue-500 text-white">
    <Container
      wide
      class="flex flex-col items-center justify-center text-custom-blue-50"
    >
      <Logo
        mode="lightest"
        class="py-8 w-60"
      />
      <Menu
        class="mb-8 flex flex-col sm:flex-row"
        investor
        hide-on-mobile
        light
        justify="justify-center"
        @go-to-index="$emit('go-to-index', $event)"
      />
      <div class="mb-8 mt-8">
        <div class="flex flex-wrap container mx-auto space-x-4 justify-center py-4">
          <p><strong>Adress</strong>: Humlegårdsgatan 4, 114 46 Stockholm</p>
          <p>
            <strong>E-post</strong>: <a
              href="mailto:info@windon.se"
              class="text-white"
            >info@windon.se</a>
          </p>
          <p><strong>Org.nummer</strong>: 559341-3692</p>
          <p>
            <strong>Telefon</strong>: <a
              href="tel:+46 72 942 48 92"
              class="text-white"
            >+46 72 942 48 92</a>
          </p>
        </div>

        <!-- Adress: Humlegårdsgatan 4, 114 46 Stockholm

        E-post: <a
          href="mailto:info@windon.se"
          class="text-white"
        >info@windon.se</a>

        Org.nummer: 559341-3692

        Telefon: <a
          href="tel:+46 72 942 48 92"
          class="text-white"
        >+46 72 942 48 92</a> -->
      </div>
      <div class="mb-8 mt-8 text-white">
        © {{ (new Date).getFullYear() }} Windon Energy Group
      </div>
    </Container>
  </div>
</template>

<script>

export default {
  name: 'Footer',
  props: {
  }
}
</script>
