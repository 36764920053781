import Home from "@/views/Home"
import Products from "@/views/Products"
import Calculator from "@/views/Calculator"
import Installer from "@/views/Installer"
import About from "@/views/About"
import IR from "@/views/IR"
import Login from "@/views/Login"
import CompanyManagement from "@/views/CompanyManagement"

import Contact from "@/views/Contact"
import auth from "@/js/helpers/auth"
import { createRouter, createWebHistory } from "vue-router"
import { useUsersStore } from "../stores/users"

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  base: process.env.BASE_URL,
  linkExactActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/om-windon",
      name: "about",
      component: About,
    },
    {
      path: "/produkter/:type?",
      name: "products",
      component: Products,
    },
    {
      path: "/solpanelsoffert",
      name: "calculator",
      component: Calculator,
    },
    {
      path: "/installatorer",
      name: "installer",
      component: Installer,
    },
    {
      path: "/ir/:selected?",
      name: "ir",
      component: IR,
    },
    {
      path: "/bolagsstyrning/:selected?",
      name: "bolagsstyrning",
      component: CompanyManagement,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/contact",
      name: "contact",
      component: Contact,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (localStorage.getItem("group-user-login")) {
    useUsersStore().fetch()
  }

  const tryToAccessUserProtectedUrlWithoutAccess =
    to.matched.some((m) => m.meta.protected) && !auth.isAuthenticated()

  if (tryToAccessUserProtectedUrlWithoutAccess) {
    auth.clearToken()
    next({ name: "login" })
  } else {
    next()
  }
})

export default router
