<template>
  <Calculator
    :years="years"
    :beauty-numbers="beautyNumbers"
    :total-columns="numberOfColumns"
    :average-minimum-saving="averageMinimumSaving"
    :average-minimum-cost="averageMinimumCost"
    :total-average-yearly-energy="totalAverageYearlyEnergy"
    :average-maximum-cost="averageMaximumCost"
    :average-maximum-saving="averageMaximumSaving"
  />
</template>

<script>

import Calculator from './_components/Calculator'

export default {
  name: 'CalculatorModule',
  components: {
    Calculator
  },
  data () {
    return {
      rows: [],
      numberOfColumns: 10,
      selected: "370",
      years: 50,
      averageMinimumSaving: 1.6,
      averageMaximumSaving: 1.7,
      averageMinimumCost: 8500,
      averageMaximumCost: 11500,
      options: [
        {
          value: "370",
          label: "Windon Electa 370",
          width: 107,
          height: 176,
          averageYearlyKwh: 370,
        },
      ],
    }
  },
  computed: {
    totalAverageYearlyEnergy () {
      return this.numberOfColumns * this.selectedPanel.averageYearlyKwh
    },
    selectedPanel () {
      return this.options.find(o => o.value === this.selected)
    }
  },
  methods: {
    beautyNumbers (input) {
      return parseFloat(input).toLocaleString('en-US', {maximumFractionDigits: 2}).replace(/,/g, ' ')
    }
  }
}
</script>
