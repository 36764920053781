<template>
  <ContactForm
    :loading="loading"
    :name="name"
    :email="email"
    :subject="subject"
    :message="message"
    @name="name=$event.target.value"
    @email="email=$event.target.value"
    @subject="subject=$event.target.value"
    @message="message=$event.target.value"
    @submit-contact="submitContact"
  />
</template>

<script>

import ContactForm from './_components/ContactForm.vue'
import { useContactStore } from '@/stores/contact'

export default {
  name: 'ContactWindonModule',
  components: {
    ContactForm
  },
  setup () {
    const contactStore = useContactStore()

    return { contactStore }
  },
  data () {
    return {
      name: '',
      email: '',
      subject: '',
      message: ''
    }
  },
  methods: {
    submitContact () {
      let payload = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message
      }
      
      this.contactStore.submitContact(payload)
    }
  }
}
</script>
