<template>
  <div class="w-full h-full">
    <HeaderContainer @go-to-index="$emit('go-to-index', $event)" />
    <main class="h-full w-full">
      <slot />
      <!-- <RouterView :key="$route.fullPath" />    -->
    </main>
    <PortalTarget
      name="dialog"
      multiple
    />
  </div>
</template>

<script>

import HeaderContainer from '@/modules/header'
import { PortalTarget } from 'vue3-portal'

export default {
  name: 'Layout',
  components: {
    HeaderContainer,
    PortalTarget
  },
  
}
</script>

<style lang="scss">
.router-animation-enter-active {
  animation: coming 0.2s;
  animation-delay: 0.1s;
  opacity: 0;
}
.router-animation-leave-active {
  animation: going 0.2s;
}


@keyframes going {
  from {
    transform: translate3d(0, 0, 0) scale(1);
  }
  to {
    transform: translate3d(0, 4%, 0) scale(0.93);
    opacity: 0;
  }
}
@keyframes coming {
  from {
    transform: translate3d(0, 4%, 0) scale(0.93);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
}
</style>