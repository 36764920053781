<template>
  <Container wide>
    <h1 class="text-custom-gray-900 font-extrabold text-2xl z-30 flex items-center">
      Solparker
    </h1>

    <div class="text-lg font-extrabold">
      Solpark, är det något för sverige?
    </div>
    <p class="mb-4">
      Solpark är något som har varit ganska ovanligt i Sverige. Det har spritt sig mycket i Europa på grund av god ekonomi med hjälp av subventioner såsom bra elcertifikat. Vi kommer dock att få se mer av denna vara i Sverige nu när priserna på solceller och kringutrustning sjunkit och det blir allt svårare att hitta platser för vindkraft / vattenkraft. Naturligtvis spelar priserna på elen också in och trenden vi i framtiden ser är ett ökat behov av el samt stora krav på att elen ska vara producerat på ett miljöriktigt sätt. Sen har vi ju dom mjuka värdena där det är ett mycket miljöriktigt alternativ, dessutom en underhållsfri produkt samtidigt som det inte låter eller syns på samma sätt som t.ex. vindkraft. Solenergi lämnar dessutom inte heller några spår och kan också med små medel monteras ner och flyttas om den plats man valt måste användas till något annat. Det är lätt att bygga vidare och bygga ut och kräver nära nog inget underhåll om man väljer rätt produkter. Dessutom kommer en solpark av rätt kvalité att producera mer energi under sin livstid än motsvarande investering i vindkraft samtidigt som man inte behöver oroa sig för att störa djur och människor med skuggor och ljud. Vi har under vår tid i solenergibranschen inte stött på en enda människa som varit negativ till denna teknik utan alla har varit mycket positiva och intresserade av vad vi gör. Man känner verkligen att man gör en insats för miljö och människa. Vi har låtit elever vara med och montera solpark och det verkar som om dom tycker det är både spännande och kul, samtidigt har vi naturligtvis hållit utbildning i tekniken för att skapa en förståelse för förnyelsebar och hållbar energi till nästa generation.
    </p>
    <div class="text-lg font-extrabold">
      Vad ger en solpark i energi?
    </div>
    <p class="mb-4">
      Det finns idag en handfull solanläggningar i Sverige som man kan benämna solpark, den största är solparken MEGA-SOL i Arvika som har en installerad effekt på mer än 1000 000 Watt. Denna park är byggd av Windon med ett innehåll på 4080st svenskproducerade solcellsmoduler på ca 255Watt/st, över 60 ton svenskproducerat ställningsmaterial. Det mest unika med denna solpark är att mer än 92% av kostnaden i solparken är svenskproducerat vilket ju ger ytligare ett viktigt mjukt värde i form av svenska jobb samtidigt som man kan försäkra sig om att den har en hög kvalité. Denna solpark kommer under sin livslängd att producera mer än 45 000 000 kWt (45 000MWt) vilket skulle förse mer än 18000 lägenheter med energi i ett helt år. Då inser man att man kommer få tillbaka investeringen minst 2ggr under parkens livstid. Med den ekonomin finns det inget försvar mot at Jobbigt med alla nollor inte satsa miljövänligt. När parken är förbrukad och ska monteras ner så går det att återvinna 100% av ingående material och nermonteringen går på några dagar.
    </p>
    <div class="text-lg font-extrabold">
      Går det bygga med svensktillverkat material?
    </div>
    <p class="mb-4">
      Windon har tagit fram ett markställningsmaterial för solpark som är helt unikt som ger en mycket god ekonomi både vid inköp och montage. Stativen är tillverkade av Tranås Rostfria AB i Tranås Sverige. Det är ett enradigt stativ som är möjligt att montera i alla vinklar. Tekniken vi använder för att sätta fundamentet i solparken är med hjälp av pål-drivning, i dom fall vi har berg så är det traditionell borrning som är metoden. Det är ett mycket snabbmonterat material och en solpark på ca 1MW sätter vi på ca 4 veckor med en pålkran under normala markförhållanden. Solcellsmodulerna vi använder är Windons egna som designats av företaget Swemodule AB i Sverige, allt kringmaterial såsom kablage, buntband mm kommer från OEM Automatik i Tranås. Kort sagt är det mycket hög kvalité på materialet vilket är nödvändigt om solparken ska kunna leverera el den utlovade tiden. Vi är rätt säkra på att vår utrustning kommer att generera bättre ekonomi under sin livslängd än någon av våra konkurrenter.
    </p>
    <div class="text-lg font-extrabold">
      Solpark Mega-Sol i Arvika störst i Sverige.
    </div>
    <p class="mb-4">
      Nu har vi invigt solpark Mega-Sol i Arvika, Sveriges idag största solpark. 2015-02-08 invigde vi solparken Mega-Sol i Arvika. På söndagen driftsattes samtliga inverterar och kl 11:00 vid provstart av parken producerade den 95% av sin totala kapasitet, detta är ett otroligt bra värde för en vintermånad i Mellansverige. Kort sagt har vi här bevisat att solenergi verkligen är något som fungerar även så här långt norr ut. Förutfattade meningar har varit att solceller inte ger någon energi på vintern här men det är med våra solceller idag endast en myt. Våra svenska solceller har ett frontglas som är behandlat på ett sätt att det absorberar ljus även i mycket dåliga ljusförhållanden (unik etsbehandling av AR glas) och på ställningar får de en mycket bra avkylning och kan då leverera väldigt bra resultat. Invigningen var mycket lyckad och allt flöt på enligt förhoppningar, SVT och tidningar var på platts för att uppmärksamma invigningen och naturligtvis var Windon och Swemodule som leverantör på platts samt vår kund Arvika Kraft och naturligtvis en massa andra nyfikna människor.
    </p>
  </Container>
</template>

<script>

export default {
  name: 'SolarPark',
  props: {
  }
}
</script>
