<template>
  <div
    class="relative flex flex-col h-full"
  >
    <LoginContainer />
  </div>
</template>

<script>

import LoginContainer from '@/modules/login'

export default {
  name: 'Login',
  components: {
    LoginContainer
  }
}
</script>
