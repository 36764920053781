import { defineStore } from "pinia"
import RepositoryFactory from "@/api/repositoryFactory"
import auth from "@/js/helpers/auth"
import { useSnackbarStore } from "./snackbar"
import router from "@/js/router"

const repository = RepositoryFactory.get("users")

export const useUsersStore = defineStore("users", {
  state: () => ({
    users: [],
    roles: [],
    user: null,
    accessToken: null,
    isLoggedIn: false,
    loadingLogin: false,
    locked_at: null,
    locked_to: null,
    loginError: "",
  }),
  getters: {},
  actions: {
    async fetch() {
      if (auth.getAccessToken()) {
        // console.log('TOKEN TOKEN TOKEN', auth.getAccessToken())

        this.setToken(auth.getAccessToken())
      }

      // console.log('USER FETCH USER FETCH USER FETCH')

      if (localStorage.user !== undefined && localStorage.user !== "") {
        this.setUser(JSON.parse(localStorage.user))
      }
      if (localStorage.roles !== undefined && localStorage.roles !== "") {
        this.setRoles(JSON.parse(localStorage.roles))
      }

      if (localStorage.getItem("isLoggedIn")) {
        this.isLoggedIn = JSON.parse(localStorage.isLoggedIn)
      }

      if (this.accessToken) {
        try {
          const response = await repository.me()
          if (response.data.username !== "Guest") {
            this.setUser(response.data)

            this.setRoles(response.data.roles)
          }
        } catch (e) {
          if (e && e.response && e.response.status === 401) {
            this.logout()
          }
          const snackbar = useSnackbarStore()
          snackbar.snack({ type: "error", e: e, show: true, autoclose: true })
        }
      }
    },
    async login(payload) {
      try {
        this.loadingLogin = true

        const response = await repository.login(payload)
        this.loadingLogin = false
        if (response.status === 200) {
          if (response.data.token && response.data.token.length > 0) {
            localStorage.setItem(
              "group-user-login",
              "oijasiduhasidoawouahwoduygahwoudyga"
            )
            // console.log('login data', response.data.user)
            this.isLoggedIn = true
            // dispatch('setFirebaseToken', localStorage.getItem('fcmToken'))
            this.setToken(response.data.token)
            this.setUser(response.data.user)
            this.setRoles(response.data.user.roles)

            router.push({
              name: "home",
            })
          }
        } else {
          this.loginError = JSON.parse(response.message)[0]
        }
      } catch (e) {
        const snackbar = useSnackbarStore()

        snackbar.snack({ type: "error", e: e, show: true, autoclose: true })

        this.loadingLogin = false
        if (e && e.response && e.response.data) {
          // commit('SET_ALERT', { create: true,  title: 'Login Error!', body: 'Invalid credentials. please check your Email Id or Password' })
          this.loginError = e.response.data.message
        }
      }
    },
    async logout() {
      try {
        // await firebaseLogout()

        localStorage.isLoggedIn = false
        localStorage.isLoggedIn = false
        localStorage.removeItem("group-user-login")

        this.accessToken = ""
        this.user = null
        auth.clearToken()
        this.isLoggedin = false

        router.push({ name: "home" })
      } catch (e) {
        useSnackbarStore().snack({
          type: "error",
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    setToken(token) {
      this.accessToken = token
      auth.storeToken(token)
    },
    setUser(user) {
      this.user = { ...user }
      localStorage.user = JSON.stringify(this.user)
    },
    setRoles(roles) {
      this.roles = [...roles]
      localStorage.roles = JSON.stringify(this.roles)
    },
    setTestAction(newValue) {
      this.test = newValue
    },
  },
})
