<template>
  <div
    class="relative flex flex-col"
  >
    <div class="relative">
      <h1 class="absolute inset-0 z-30 flex items-center justify-center text-white text-shadow lg:text-6xl text-3xl md:text-4xl font-extrabold">
        Om Windon
      </h1>
      <div class="bg-custom-gray-900 bg-opacity-50 absolute inset-0 z-20" />
      <BannerImage
        src="/about.jpg"
        ratio="aspect-w-16 aspect-h-7 md:aspect-h-5 lg:aspect-h-4"
      />
    </div>
    <Container
      wide
      class="z-20 relative"
    >
      Om Windon
    </Container>    
  </div>
</template>

<script>
import { useAppStore } from '@/stores/app'

export default {
  name: 'About',
  setup () {
    const appStore = useAppStore()

    return { appStore }
  }
}
</script>
