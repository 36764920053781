<template>
  <header
    class="bg-white text-gray-900 z-20 w-full fixed top-0"
  >
    <Container
      full
      padding="p-0"
      class="relative py-0 px-4 lg:px-16 flex lg:items-center items-center justify-between z-20 relative"
    >
      <div
        v-if="user"
        class="text-custom-blue-500 p-4 absolute left-0 top-0 flex flex-col justify-center"
      >
        <Icon
          name="user-circle"
          solid
          class="text-4xl h-8"
        />
        <span class="text-xs">{{ user.first_name }}</span>
      </div>
      <RouterLink :to="{name: 'home'}">
        <Logo class=" p-4" />
      </RouterLink>
      <Menu
        class="justify-end"
        @go-to-index="$emit('go-to-index', $event)"
      />
    </Container>
  </header>
</template>

<script>


// import MenuContainer from '@/modules/menu'

export default {
  name: 'HeaderInternal',
  props: {
    user: {
      type: Boolean,
      default: false
    }
  }
}
</script>
