<template>
  <CalculatorContainer />
</template>

<script>
import CalculatorContainer from '@/modules/calculator'

export default {
  name: 'Calculator',
 
  components: {
    CalculatorContainer
  }
}
</script>
