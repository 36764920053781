<template>
  <div
    class="relative flex h-full w-full pt-4 mt-20"
  >
    <!-- <div class="w-[300px] flex flex-col">
      <div
        :class="selected === '' ? 'font-bold' : ''"
        class="p-4 border-b"
        @click="selected = ''"
      >
        Investerare
      </div>
      <div
        :class="selected === 'goals' ? 'font-bold' : ''"
        class="p-4 border-b"
        @click="selected = 'goals'"
      >
        Finansiella mål
      </div>
      <div
        :class="selected === 'contact' ? 'font-bold' : ''"
        class="p-4 border-b"
        @click="selected = 'contact'"
      >
        IR-kontakt
      </div>
    </div> -->
    <div class="w-full h-full pt-6">
      <div
        v-if="showLoader"
        class="flex items-center justify-center p-4"
      >
        <CircleLoader black />
      </div>
      <iframe
        class="w-full h-full overscroll-auto"
        src="https://ir.spotlightstockmarket.com/sv/bolag/irabout?CompanyID=9046"
      />
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/stores/app'

export default {
  name: 'IR',
  setup () {
    const appStore = useAppStore()

    return { appStore }
  },
  data () {
    return {
      selected: '',
      showLoader: true
    }
  },
  mounted () {
    const self = this
    this.selected = this.$route.params.selected ? this.$route.params.selected : ''

    if(this.selected === '') {
      this.showLoader = true
    }

    setTimeout(() => {
      self.showLoader = false
    }, 2000)
  }
}
</script>
