<template>
  <div class="w-full flex flex-col items-center pt-20">
    <form
      class="max-w-[500px] p-4 w-full"
      @submit.prevent="$emit('login')"
    >
      <div
        v-if="loginError"
        class="bg-red-100 px-4 py-2 text-red-900 mt-6"
      >
        {{ loginError }}
      </div>
      <BaseCard class="mt-8 p-6">
        <BaseInput
          class="w-full px-6 bg-dark focus:outline-none"
          placeholder="Email / Username"
          :value="email"
          required
          @input="$emit('email', $event)"
        />
        <BaseInput
          type="password"
          class="w-full mt-3 px-6 focus:outline-none"
          placeholder="Password"
          :value="password"
          required
          @input="$emit('password', $event)"
        />
      </BaseCard>
      <ActiveButton class="w-full text-md mt-8 h-12 focus:outline-none">
        Logga in
      </ActiveButton>
    </form>
  </div>
</template>

<script>

export default {
  name: 'Login',
  props: {
    email: { type: String, default: '' },
    password: { type: String, default: '' },
    loginError: { type: String, default: '' },
    native: { type: Boolean, default: false }
  }
}

</script>
