import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('contact')
import { defineStore } from 'pinia'

export const useContactStore = defineStore('contact', {
  actions: {
    async submitContact (payload) {
      await repository.submitContact(payload)
    }
  }
})
