<template>
  <Container wide>
    <ColumnWrapper
      row
      class="flex-col lg:flex-row"
    >
      <LeftColumn>
        <h1 class="text-custom-gray-900 mb-4 font-extrabold text-2xl z-30 flex items-center">
          Solpaneler
        </h1>
        <div class="flex flex-col xs:flex-row items-center mb-8">
          <div class="flex flex-row w-full xs:w-1/3 p-1">
            <div class="font-bold w-full p-2 py-0 xs:py-4 text-center flex flex-row xs:flex-col justify-start xs:justify-center items-center">
              <img
                src="/check-icon.svg"
                class="mb-0 xs:mb-4 mr-2 xs:mr-0 w-10 xs:w-14"
              >
              <p>Högre effektgenerering</p>
            </div>
          </div>
          <div class="flex flex-row w-full xs:w-1/3 p-1">
            <div class="font-bold w-full p-2 py-0 xs:py-4 text-center flex flex-row xs:flex-col justify-start xs:justify-center items-center">
              <img
                src="/brush-icon.svg"
                class="mb-0 xs:mb-4 mr-2 xs:mr-0 w-10 xs:w-14"
              >

              <p>Underhållsfria solpaneler</p>
            </div>
          </div>
          <div class="flex flex-row w-full xs:w-1/3 p-1">
            <div class="font-bold w-full p-2 py-0 xs:py-4 text-center flex flex-row xs:flex-col justify-start xs:justify-center items-center">
              <img
                src="/check-square-icon.svg"
                class="mb-0 xs:mb-4 mr-2 xs:mr-0 w-10 xs:w-14"
              >

              <p>50 års effektgaranti</p>
            </div>
          </div>
        </div>

        <div class="text-lg font-extrabold">
          Vi satsar på kvalité!
        </div>
        <p class="mb-4">
          Windon har en solcellsmodul som idag är ganska ovanlig på marknaden där vi lagt stor vikt vid kvalité och ingående material. Vi har en produkt med ett materialinnehåll som har varit i produktion i över 15 år vilket garanterar att valt material kommer att hålla en god status under lång tid.
        </p>

        <p class="mb-4">
          Vi har valt att ligga kvar i utvecklingen med stabilt och beprövat ingående material och satsar istället på att byta de få komponenter som ger en högre effekt och bättre el-produktion.
        </p>

        <div class="text-lg font-extrabold">
          Vårt etsade glas
        </div>
        <p class="mb-4">
          Vårt solpanel har en etsat frontglas som är självrengörande och klarar av att hålla modulen ren från smuts och avlagringar! Att åka runt och tvätta och behandla solcellsmoduler är en miljardindustri i Europa vilket gynnar den branschen men inte våra kunder. På Windon tycker vi att en solpanel ska vara helt underhållsfri och då naturligtvis utan att med åren tappa en massa energi, därför har vi valt att behandla vårt glas på ett annorlunda sätt än övriga tillverkare.
        </p>
        <p class="mb-4">
          Windons mest unika komponent är vårt glas. Vi har genom åren jobbat en hel del med vårt modulglas, detta på grund av att det är en komponent som kommer att avgöra hur mycket solenergi som når kislet, Vi har jobbat både med absorbering av ljus och för att få en yta på nanonivå som klarar av att hålla modulen ren från smuts och avlagringar.
        </p>

        <p class="mb-4">
          Vad skiljer då vårt glas mot ett planglas eller en AR-coat? Ja för det första så har vi ingen pålagd yta på vårt glas utan med hjälp av en ets-behandling får vi vår yta på solcellsmodulen. Kollar man på en AR-coat så består den av en Polyuretan eller likvärdig UV-tålig lack, man kan jämställa den ytan med en billack eller båtlack. En sådan yta måste för att behålla sin effekt rengöras som en vanlig bil, dessutom blir den med åren sliten och kommer då att ta åt sig smuts ändå lättare vilket betyder att det blir mer jobb med modulen med åren.
        </p>

        <p class="mb-4">
          Detta kan man naturligtvis bättra på med hjälp av samma behandling man ger sin bil i form av vaxning eller liknande. Har man otur och köper en produkt med en dålig yt-behandling så kan den till och med släppa i en framtid “flagna”. Detta påverkar inte panelens egentliga funktion men naturligtvis så kommer ett smutsigt glas att försämra panelens prestanda.
        </p>

        <p class="mb-4">
          Tester vi har gjort visar att en coatad yta har en hygglig effekt i 4-7 år och sen börjar den bli ganska repig och mottaglig för påväxt och smuts precis som en billack, håller man inte efter den så kommer den att åldras mycket snabbare. Mätningar som vi och bl.a. Kercher har gjort visar att smutspåväxt på en coatad modul når upp till hela 25%, ganska stora förluster.
        </p>

        <p class="mb-4">
          Vi lägger inte på en yta utan vi tar istället bort material för att få en behandlad anti-reflexyta på våra moduler. Detta ger då många fördelar bl.a. blir modulen med sin nano-yta helt självrengörande och det har visat sig att endast 1.8% påväxt blir kvar i genomsnitt på våra modulglas. En till fördel är att glaset inte har någon slityta och kommer alltså se som ny ut om 50 år. Utöver detta så har vi lyckats absorbera ljuset så bra att vi har en transmistans som ger modulen en ökad energi på 6-8%, allt detta på grund av ett väl valt glas.
        </p>

        <p class="mb-4">
          Vi har dessutom lyckats att få ner Gloss-värdet (reflektionen) med upp till 300% på vår Aeromodul vilket gör att vi idag är den enda tillverkaren som kan erbjuda en modul som kan användas med säkerhet på flygplatser och andra ställen som är känsliga för reflektioner. Detta har vi lyckats med utan att tumma på energiuttaget och själv-rengöringen.
        </p>
      </LeftColumn>
      <RightColumn class="mt-8 lg:mt-0 lg:pl-4">
        <h3 class="text-custom-gray-900 mb-4 font-extrabold text-2xl z-30 flex items-center">
          Dokument
        </h3>
        <DownloadButton class="mb-2">
          <Icon
            name="file"
            class="text-xl mr-2"
          /><div>Kvalitét och Garantier</div>
        </DownloadButton>
        <DownloadButton class="mb-2">
          <Icon
            name="file"
            class="text-xl mr-2"
          /><div>Windons glas</div>
        </DownloadButton>
        <DownloadButton class="mb-2">
          <Icon
            name="file"
            class="text-xl mr-2"
          /><div>Brand- och Elsäkerhet</div>
        </DownloadButton>
        <DownloadButton class="mb-2">
          <Icon
            name="file"
            class="text-xl mr-2"
          /><div>Solinstrålning</div>
        </DownloadButton>
      </RightColumn>   
    </ColumnWrapper>
  </Container>
</template>

<script>

export default {
  name: 'Solpaneler',
  props: {
  }
}
</script>
