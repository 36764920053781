<template>
  <BaseButton
    class="text-white"
    :class="cold ? 'bg-custom-blue-500' : 'bg-custom-orange-500'"
  >
    <slot />
  </BaseButton>
</template>

<script>

export default {
  name: 'ActiveButton',
  props: {
    cold: {
      type: Boolean,
      default: false
    }
  }
}
</script>
