<template>
  <div
    class="relative flex flex-col"
  >
    <div class="bg-custom-blue-500 text-white">
      <Container
        wide
        padding="py-16"
      >
        <div>
          <BigTitle large>
            Här är ditt kostnadsförslag för solpaneler
          </BigTitle>
          <SubTitle>Detta är en uppskattning för Magasinsgatan 5B</SubTitle>
        </div>
      </Container>
    </div>
    <div class="bg-custom-blue-600 text-white ">
      <Container
        wide
        padding="py-2"
        class="flex items-center justify-between"
      >
        <div
          class="flex items-center"
        >
          <div>
            <div class="text-sm">
              Uppskattat prisspann
            </div>
            <div class="font-bold text-lg">
              {{ beautyNumbers(averageMinimumCost * totalColumns) }} kr - {{ beautyNumbers(averageMaximumCost * totalColumns) }} kr
            </div>
          </div>
          <div class="border-r border-white mx-8 h-4" />
          <div>
            <div class="text-sm">
              Uppskattad besparing
            </div>
            <div class="font-bold text-lg">
              {{ beautyNumbers(averageMinimumSaving * totalColumns * totalAverageYearlyEnergy * years) }} kr - {{ beautyNumbers(averageMaximumSaving * totalColumns * totalAverageYearlyEnergy * years) }} kr
            </div>
          </div>
        </div>
        <ActiveButton>Gå vidare</ActiveButton>
      </Container>
    </div>
    <div class="bg-custom-gray-100 text-custom-gray-900">
      <Container
        wide
        padding="py-16"
        class=""
      >
        <BigTitle large>
          Förslag på uppsättning
        </BigTitle>
        <SubTitle large>
          Solcellssystem
        </SubTitle>
        <SubTitle>
          Med detta system beräknas du generera {{ beautyNumbers(totalAverageYearlyEnergy) }} kWh per år
        </SubTitle>
        <div class="flex mt-8">
          <div class="w-1/2 pr-4">
            <div class="bg-white border-b border-custom-gray-200 flex items-center">
              <div class="w-32 h-32 flex items-center justify-center bg-custom-blue-300 text-white">
                <Icon
                  name="grid"
                  class="text-6xl"
                />
              </div>
              <div class="flex justify-between w-full items-center">
                <div class="p-4 px-8">
                  <SubTitle
                    large
                    bold
                  >
                    Solpanel Premium
                  </SubTitle>
                  <SubTitle>{{ totalColumns }} x premiumpaneler</SubTitle>
                  <div>196 W/m2</div>
                </div>
                <div class="w-32 h-32 flex items-center justify-center  text-custom-gray-900">
                  <Icon
                    name="pencil"
                    solid
                    class="text-3xl"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-1/2 pl-4">
            <div class="bg-white border-b border-custom-gray-200 flex items-center">
              <div class="w-32 h-32 flex items-center justify-center bg-custom-blue-300 text-white">
                <Icon
                  name="battery-charging"
                  solid
                  class="text-6xl"
                />
              </div>
              <div class="p-4 px-8">
                <SubTitle
                  large
                  bold
                >
                  Batterisystem
                </SubTitle>
                <SubTitle>Lagra din el</SubTitle>
                <div>Läs mer här</div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
    <div class="bg-white">
      <Container
        wide
        padding="py-16"
      >
        <BigTitle large>
          Besparing
        </BigTitle>
        <div class="flex items-center mt-8">
          <div class="w-1/2 pr-4">
            <div class="bg-white flex items-center">
              <div class="w-32 h-32 flex items-center justify-center bg-custom-blue-300 text-white">
                <Icon
                  name="wallet"
                  class="text-6xl"
                />
              </div>
              <div class="p-4 px-8">
                <SubTitle large>
                  Uppskattad besparing
                </SubTitle>
                <BigTitle>{{ beautyNumbers(averageMinimumSaving * totalColumns * totalAverageYearlyEnergy * years) }} kr - {{ beautyNumbers(averageMaximumSaving * totalColumns * totalAverageYearlyEnergy * years) }} kr</BigTitle>
              </div>
            </div>
          </div>
          <div class="w-1/2 pl-4">
            De uppskattade besparingarna över <strong>{{ years }} år</strong> är baserade på en månatlig faktura på 1 810 kr och den valda paketproduktionen av <strong>{{ beautyNumbers(totalAverageYearlyEnergy) }} kWh</strong>
          </div>
        </div>
        <div class="flex mt-8">
          <div class="w-1/2 pr-4">
            <SubTitle>Vad är din månadsräkning?</SubTitle>
            <div>
              <input value="1800">
            </div>
            <div>
              Inkludera batterier
            </div>
          </div>
          <div class="w-1/2 pl-4">
            <div class="flex flex-wrap">
              <div class="relative w-1/2 h-full p-4">
                <div class="m-2 absolute z-20 inset-0 bg-gray-100 flex flex-col items-center justify-center">
                  <Icon name="wallet" />
                  <div>
                    {{ beautyNumbers(averageMinimumSaving * totalColumns * totalAverageYearlyEnergy * years) }} kr - {{ beautyNumbers(averageMaximumSaving * totalColumns * totalAverageYearlyEnergy * years) }} kr
                  </div>
                  <div class="font-bold">
                    Total besparing
                  </div>
                </div>
                <div class="aspect-w-square aspect-h-square" />
              </div>
              <div class="relative w-1/2 h-full p-4">
                <div class="m-2 absolute z-20 inset-0 bg-gray-100 flex flex-col items-center justify-center">
                  <Icon name="wallet" />
                  <div>
                    {{ beautyNumbers(averageMinimumSaving * totalColumns * totalAverageYearlyEnergy * years) }} kr - {{ beautyNumbers(averageMaximumSaving * totalColumns * totalAverageYearlyEnergy * years) }} kr
                  </div>
                  <div class="font-bold">
                    Total besparing
                  </div>
                </div>
                <div class="aspect-w-square aspect-h-square" />
              </div>
              <div class="relative w-1/2 h-full p-4">
                <div class="m-2 absolute z-20 inset-0 bg-gray-100 flex flex-col items-center justify-center">
                  <Icon name="wallet" />
                  <div>
                    {{ beautyNumbers(averageMinimumSaving * totalColumns * totalAverageYearlyEnergy * years) }} kr - {{ beautyNumbers(averageMaximumSaving * totalColumns * totalAverageYearlyEnergy * years) }} kr
                  </div>
                  <div class="font-bold">
                    Total besparing
                  </div>
                </div>
                <div class="aspect-w-square aspect-h-square" />
              </div>
              <div class="relative w-1/2 h-full p-4">
                <div class="m-2 absolute z-20 inset-0 bg-gray-100 flex flex-col items-center justify-center">
                  <Icon name="wallet" />
                  <div>
                    {{ beautyNumbers(averageMinimumSaving * totalColumns * totalAverageYearlyEnergy * years) }} kr - {{ beautyNumbers(averageMaximumSaving * totalColumns * totalAverageYearlyEnergy * years) }} kr
                  </div>
                  <div class="font-bold">
                    Total besparing
                  </div>
                </div>
                <div class="aspect-w-square aspect-h-square" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Calculator',
  props: {
    years: {
      type: Number,
      default: 0
    },
    totalColumns: {
      type: Number,
      default: 0
    },
    beautyNumbers: {
      type: Function,
      default: () => {}
    },
    averageMinimumSaving: {
      type: Number,
      default: 0
    },
    averageMaximumSaving: {
      type: Number,
      default: 0
    },
    averageMinimumCost: {
      type: Number,
      default: 0
    },
    averageMaximumCost: {
      type: Number,
      default: 0
    },
    totalAverageYearlyEnergy: {
      type: Number,
      default: 0
    },
  }
}
</script>
