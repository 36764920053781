<template>
  <div
    class=""
    :class="row ? '' : 'flex-col'"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'ColumnWrapper',
  props: {
    row: {
      type: Boolean,
      default: false
    }
  }
}
</script>
