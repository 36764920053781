<template>
  <div
    class="bg-main-background font-standard font-medium font-base text-gray-900 w-full h-full"
  >
    <Layout @go-to-index="goToIndex">
      <router-view
        :key="$route.fullPath"
        :show-apex-chart="showApexChart"
        :show-growth="showGrowth"
        class="h-full flex flex-col flex-1"
        @swiper="onSwiper"
        @scroll-slide="scrollSlide"
        @go-to-index="goToIndex"
      />
    </Layout>
  </div>
</template>

<script>
import 'boxicons/css/boxicons.min.css'

import Layout from '@/layout/Layout'
import 'swiper/css';
import "swiper/css/scrollbar";
import 'swiper/css/pagination';
import { Mousewheel, Pagination } from 'swiper';

export default {
  name: 'App',
  components: {
      Layout,
    },
  setup () {

    return { modules: [Mousewheel, Pagination] }
  },
  data () {
    return {
      swiper: null,
      showApexChart: true,
      showGrowth: false
    }
  },
  
  computed: {
    layout () {
      return this.$route.meta.layout ? this.$route.meta.layout : 'standard'
    },
    chartHeight () {
      console.log(window.innerWidth)
      return window.innerWidth > 800 ? 550 : 350
    }
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    goToIndex (index) {
      console.log("index", index)
      console.log(this.swiper)
      this.swiper.slideTo(index, 350)
    },
    scrollSlide (event) {
      const self = this
      this.showGrowth = false
      if(event.activeIndex === 1) {
        setTimeout(() => {
          self.showGrowth = true
        },200)
      }

      self.showApexChart = false
      
      if(event.activeIndex === 2) {
        setTimeout(() => {
          self.showApexChart = true
        },200)
      }
    }
  }
}
</script>

<style lang="scss">
  html,
  body {
    position: relative;
    height: 100%;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    height: auto !important;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .vue-apexcharts {
      width: 100%;
  }

  .apexcharts-text {
    color: #ffffff !important;
  }

  .apexcharts-tooltip {
    background: #373737 !important;
    color: #ffffff;
  }

  p { 
    font-size: 14px;
    
    @media only screen and (min-width: 500px) {
      font-size: 14px;
    }
    @media only screen and (min-width: 700px) {
      font-size: 16px;
    }
    @media only screen and (min-width: 900px) {
      font-size: 18px;
    }

  }

  .ql-editor {
    font-family: Urbanist;
  }

  .ql-picker-item[data-value="smaller"] {
    /* font-size: 0.75em; */
    font-size: 10px;

    &::before {
      content: 'Smaller';
    }
  }
</style>