import { createApp, h } from "vue"

import App from "./App.vue"
import router from "./js/router"
// import { i18n } from '@/js/language/'
// import store from './js/store'
import { createPinia } from "pinia"
import "@/assets/css/tailwind.scss"
import { registerComponents } from "./js/autoload"

import "./registerServiceWorker"
import Portal from "vue3-portal"
import VueApexCharts from "vue3-apexcharts"

// import { StatusBar, Animation } from '@capacitor/status-bar'

// StatusBar.setStyle({ animation: Animation.SLIDE })
// StatusBar.setBackgroundColor({ color: '#543db2' })

import { QuillEditor } from "@vueup/vue-quill"
import "@vueup/vue-quill/dist/vue-quill.snow.css"

const app = createApp({
  setup: () => () => h(App),
})

// app.use(i18n)
app.use(createPinia())
app.use(router)
app.use(Portal)
app.component("QuillEditor", QuillEditor)
app.use(VueApexCharts)

registerComponents(app)

app.mount("#app")
