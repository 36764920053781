<template>
  <QuillEditor
    :content="content"
    theme="snow"
    :toolbar="toolbar"
    content-type="html"
    @update:content="test"
  />
</template>

<script>

import toolbar from '@/js/helpers/toolbar'

export default {
  name: 'Editor',
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      toolbar
    }
  },
  methods: {
    test(html) {
      console.log(html)
    }
  }
}
</script>
