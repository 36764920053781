<template>
  <Icon
    v-if="!hideOnMobile && isMobile"
    name="menu"
    class="block lg:hidden text-4xl text-custom-gray-900 cursor-pointer"
    @click="show = true"
  />
  <div
    class="uppercase items-center z-20"
    :class="[light ? 'text-white' : 'text-custom-gray-900',
             isMobile && show ? 'fixed right-0 bg-white flex flex-col w-[250px] top-0 h-screen pt-10' : isMobile ? 'hidden' : 'w-full hidden lg:flex ' + justify
    ]"
  >
    <Icon
      v-if="isMobile && show"
      name="x"
      class="text-4xl text-custom-gray-900 cursor-pointer absolute top-1 right-1"
      @click="show = false"
    />
    <div
      class="cursor-pointer"
      :class="isMobile ? 'block text-left border-b w-full hover:bg-gray-100 p-4' : 'inline-block p-2 px-4'"
      @click="goToSlide(0)"
    >
      Windon
    </div>
    <div
      class="cursor-pointer"
      :class="isMobile ? 'block text-left border-b w-full hover:bg-gray-100 p-4' : 'inline-block p-2 px-4'"
      @click="goToSlide(1)"
    >
      Tillväxtplan
    </div>
    <!-- <div
      class="cursor-pointer"
      :class="isMobile ? 'block text-left border-b w-full hover:bg-gray-100 p-4' : 'inline-block p-2 px-4'"
      @click="goToSlide(3)"
    >
      Dotterbolag
    </div> -->
    <div
      class="cursor-pointer"
      :class="isMobile ? 'block text-left border-b w-full hover:bg-gray-100 p-4' : 'inline-block p-2 px-4'"
      @click="goToSlide(3)"
    >
      Kontakt
    </div>
    <div
      class="cursor-pointer"
      :class="isMobile ? 'block text-left border-b w-full hover:bg-gray-100 p-4' : 'inline-block p-2 px-4'"
      @click="goToPage('bolagsstyrning')"
    >
      Bolagsstyrning
    </div>
    <div
      class="cursor-pointer"
      :class="isMobile ? 'block text-left border-b w-full hover:bg-gray-100 p-4' : 'inline-block p-2 px-4'"
      @click="goToPage('ir')"
    >
      IR
    </div>
  </div>
  <div
    v-if="isMobile && show"
    class="absolute inset-0 bg-custom-gray-900 bg-opacity-25 h-screen z-10"
    @click="show = false"
  />
</template>

<script>

export default {
  name: 'Menu',
  props: {
    light: {
      type: Boolean,
      default: false
    },
    hideOnMobile: {
      type: Boolean,
      default: false
    },
    investor: {
      type: Boolean,
      default: false
    },
    justify: {
      type: String,
      default: 'justify-end'
    }
  },
  data () {
    return {
      show: false,
      windowWidth: 0
    }
  },
  computed: {
    isMobile () {
      return this.windowWidth < 1024
    }
  },
  mounted () {
    const self = this
    self.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      self.windowWidth = window.innerWidth
    })
  },
  methods: {
    goToPage(page) {
      this.$router.push({name: page})
      this.show = false
    },
    goToSlide(index) {
      const self = this
      if(this.$route.path !== '/') {
        this.$router.push('/')
        setTimeout(() => {
          self.$emit('go-to-index', index)
        }, 300)
      }

      this.$emit('go-to-index', index)

      this.show = false
    }
  }
}
</script>
