<template>
  <Header
    :user="usersStore.user"
  />
</template>

<script>

import Header from './_components/Header'
import { useUsersStore } from '@/stores/users'

export default {
  name: 'HeaderModule',
  components: {
    Header
  },
  setup () {
    const usersStore = useUsersStore()

    return {usersStore}
  }
}
</script>
