<template>
  <div
    class="mx-auto dark:text-primary-dark"
    :class="[padding, bottomPadding ? 'mb-24' : '', width]"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Container',
  props: {
    padding: { type: String, default: 'py-6 px-0 sm:px-0' },
    bottomPadding: { type: Boolean, default: false },
    full: { type: Boolean, default: false },
    wide: { type: Boolean, default: false }
  },
  computed: {
    width () {
      if (this.full) {
        return 'w-full'
      }

      if (this.wide) {
        return 'w-11/12 2xl:w-8/12'
      }

      return 'w-11/12 sm:w-8/12 md:w-6/12 lg:w-5/12'
    }
  }
}
</script>
