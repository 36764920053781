<template>
  <Container wide>
    <h1 class="text-custom-gray-900 font-extrabold text-2xl z-30 flex items-center">
      Markställningar
    </h1>
    <ColumnWrapper
      row
      class="flex-col lg:flex-row"
    >
      <LeftColumn>
        <p class="mb-4">
          Windon har tagit fram en unik markställning för att kunna montera solcellspaneler på mark. Denna markställning passar sig från ca 10kW (40 paneler) upp till oändlig storlek. Det finns många fördelar med detta system om man jämför med konkurrerande markställningar. 
          Denna markställning är universal för alla sortens solpaneler och tillverkas i Sverige. Vi har utvecklat denna markställning för hårda förhållanden och alla komponenter i markställningen har minst C4 klass. Vår markställning har visat sig klara över 50m/s vilket är mer än de flesta markställningar klarar, normalt brukar markställningar vara klassade till max 28m/s.
        </p>
        <img src="/135464.jpg">
    
        <ul>
          <div class="flex items-center mb-2">
            <Icon
              name="check"
              class="mr-1 text-xl"
            />
            <p>Markställning kan som standard monteras i vinklar från 0°-85º utan något extra tillbehör.</p>
          </div>
          <div class="flex items-center mb-2">
            <Icon
              name="check"
              class="mr-1 text-xl"
            />
            <p>Denna markställning monteras ca 5ggr så snabbt som andra system på marknaden.</p>
          </div>
          <div class="flex items-center mb-2">
            <Icon
              name="check"
              class="mr-1 text-xl"
            />
            <p>Det är otroligt prisvärt även i mindre serier.</p>
          </div>
          <div class="flex items-center mb-2">
            <Icon
              name="check"
              class="mr-1 text-xl"
            />
            <p>Varmförzinkat och pulver lackat med UV-beständig färg vilket ger en otrolig motståndskraft mot både solljus, salter, syror och väta.</p>
          </div>
          <div class="flex items-center mb-2">
            <Icon
              name="check"
              class="mr-1 text-xl"
            />
            <p>Ingen detalj är längre än 3m för att minimera fraktkostnader.</p>
          </div>
          <div class="flex items-center mb-2">
            <Icon
              name="check"
              class="mr-1 text-xl"
            />
            <p>Tillåter inte ett montage där kraven på solpanelsgarantin kringgås, därav alltid full garanti på panelerna om de monterats på denna ställning.</p>
          </div>
          <div class="flex items-center mb-2">
            <Icon
              name="check"
              class="mr-1 text-xl"
            />
            <p>Klarar alla krav på last, bara att öka antal pålar så ökar hållfastheten på markställningen.</p>
          </div>
          <div class="flex items-center mb-2">
            <Icon
              name="check"
              class="mr-1 text-xl"
            />
            <p>Snabba ledtider då den tillverkas av oss i Sverige.</p>
          </div>
          <div class="flex items-center mb-2">
            <Icon
              name="check"
              class="mr-1 text-xl"
            />
            <p>Finns med fot för montering på betongtak eller fundament.</p>
          </div>
          <div class="flex items-center mb-2">
            <Icon
              name="check"
              class="mr-1 text-xl"
            />
            <p>Ingen påfrestning på panelerna, inga skruvmoment att ta hänsyn till.</p>
          </div>
          <div class="flex items-center mb-2">
            <Icon
              name="check"
              class="mr-1 text-xl"
            />
            <p>Inbyggda kabelkanaler i markställning för att dra både DC-kablage och kablaget från inverter.</p>
          </div>
          <div class="flex items-center mb-2">
            <Icon
              name="check"
              class="mr-1 text-xl"
            />
            <p>Möjligt att välja kulör på markställning (kostnad kan tillkomma i mindre serie)</p>
          </div>
        </ul>
      </LeftColumn>
      <RightColumn class="mt-8 lg:mt-0 lg:pl-4">
        <h3 class="text-custom-gray-900 mb-4 font-extrabold text-2xl z-30 flex items-center">
          Dokument
        </h3>
        <DownloadButton class="mb-2">
          <Icon
            name="file"
            class="text-xl mr-2"
          /><div>Manual</div>
        </DownloadButton>
        <DownloadButton class="mb-2">
          <Icon
            name="file"
            class="text-xl mr-2"
          /><div>Tillägg stormskiva</div>
        </DownloadButton>
      </RightColumn>   
    </ColumnWrapper>
  </Container>
</template>

<script>

export default {
  name: 'Ground',
  props: {
  }
}
</script>
