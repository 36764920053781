<template>
  <div
    class="relative flex flex-col"
  >
    <div class="flex flex-col lg:flex-row items-center  bg-custom-blue-500">
      <div class="w-full lg:w-1/2 h-full flex-shrink-0 flex-grow bg-custom-blue-500 text-white py-8 px-16 text-base md:text-lg lg:text-xl">
        <h2 class="text-white text-2xl md:text-3xl lg:text-4xl font-bold mb-8">
          Anslut dig till 97 certifierade windon-installatörer
        </h2>
        <p class="mb-4">
          Lägg in egna beställningar eller få offerter
          ifrån oss att räkna på. Ni sätter era egna priser
          i ett komplett system för leverans till slutkund
        </p>
        <RouterLink
          :to="{name: 'contact' }"
          class="text-gray-900 flex items-center mb-4 font-bold"
        >
          <ActiveButton>Bli ÅF idag</ActiveButton>
        </RouterLink>
      </div>
      <div class="w-full flex-wrap flex-col md:flex-row lg:w-1/2 flex-shrink-0 flex">
        <BannerImage
          src="/electrician.jpg"
          ratio="aspect-w-16 aspect-h-16"
        />
      </div>
    </div>
    <Container
      wide
      class="z-20 relative"
      padding="py-8"
    >
      <div class="flex flex-col lg:flex-row items-center">
        <div class="w-full lg:w-1/2 flex-shrink-0 text-custom-gray-900 p-8 -mt-6 sm:-mt-24 lg:-mt-8 mb-8 lg:-mb-8 lg:-ml-8 lg:mr-8 text-base md:text-lg lg:text-xl">
          <h2 class="text-custom-gray-900 text-2xl md:text-3xl lg:text-4xl font-bold mb-8">
            Vi har försett svenska installatörer med kvalitativa produkter i över 15 år.
          </h2>
          <p class="font-bold mb-4">
            Öka kvaliteten, halvera monteringstiden!
          </p>
          <p class="mb-4">
            Med egentillverkade produkter kan vi inte bara erbjuda en solpanel i världsklass, utan även ett helt eget montagesystem
            utvecklat för den svenska marknaden. Helt godkänt enligt Boverkets regelverk.
          </p>
          <RouterLink
            :to="{name: 'products', params: {type: 'solpaneler'} }"
            class="text-gray-900 flex items-center mb-4 font-bold"
          >
            <Icon
              name="right-arrow"
              class="mr-2"
              solid
            />
            <span>Berätta mer om era solpaneler</span>
          </RouterLink>
          <p class="mb-4">
            Vårt egenutvecklade montagesystem upp till halverar den ordinarie installationstiden för solpaneler.
          </p>
          <RouterLink
            :to="{name: 'products', params: {type: 'takmontage'} }"
            class="text-gray-900 flex items-center mb-4 font-bold"
          >
            <Icon
              name="right-arrow"
              class="mr-2"
              solid
            />
            <span>Berätta mer om montering</span>
          </RouterLink>
        </div>
        <div class="w-full flex-wrap flex-col md:flex-row lg:w-1/2 flex-shrink-0 flex mb-8 lg:mb-0">
          <div class="p-2 pb-4 lg:p-4 w-full md:w-1/2">
            <div class="flex items-center mb-2 lg:mb-4">
              <img
                src="/check-icon.svg"
                class="mr-2 w-10 md:w-14"
              >
              <span class="text-2xl xl:text-4xl font-bold">22,54%</span>
            </div>
            <p>
              22,54% cellverkningsgrad, bland de högsta i klassen.
            </p>
          </div>
          <div class="p-2 pb-4 lg:p-4 w-full md:w-1/2">
            <div class="flex items-center mb-2 lg:mb-4">
              <img
                src="/check-square-icon.svg"
                class="mr-2 w-10 md:w-14"
              >
              <span class="text-2xl xl:text-4xl font-bold">50 år</span>
            </div>
            <p>
              50 års funktionsgaranti i en rak kurva med längst garanti på marknaden.
            </p>
          </div>
          <div class="p-2 pb-4 lg:p-4 w-full md:w-1/2">
            <div class="flex items-center mb-2 lg:mb-4">
              <img
                src="/sun-icon.svg"
                class="mr-2 w-10 md:w-14"
              >
              <span class="text-2xl xl:text-4xl font-bold">Etsat glas</span>
            </div>
            <p>
              Etsat glas gör panelen underhållsfri och gynnar maximal produktion av KWH.
            </p>
          </div>
          <div class="p-2 pb-4 lg:p-4 w-full md:w-1/2">
            <div class="flex items-center mb-2 lg:mb-4">
              <img
                src="/time-icon.svg"
                class="mr-2 w-10 md:w-14"
              >
              <span class="text-2xl xl:text-4xl font-bold">Montagesystem</span>
            </div>
            <p>
              Unikt system designat och tillverkat i Sverige upp till halverar installationstiden.
            </p>
          </div>
        </div>
      </div>
    </Container>
    <div class="bg-white border-y border-gray-200 p-8 pt-16">
      <Container
        wide
        class="flex flex-col lg:flex-row"
      >
        <div class="w-full mb-8 lg:w-1/2 text-center lg:text-left font-bold text-2xl sm:text-3xl lg:text-4xl">
          Pressmeddelanden
        </div>
        <div class="w-full lg:w-1/2">
          <div
            v-if="loading"
            class="flex items-center justify-center p-4"
          >
            <CircleLoader />
          </div>
          <div v-else-if="news && news.length > 0">
            <div
              v-for="article in newsLimited"
              :key="article.title"
              class="mb-4 border py-4 px-8 border-gray-200 hover:bg-gray-100"
            >
              <a
                :href="article.link"
                target="_blank"
              >
                <h3 class="font-bold text-lg md:text-xl text-custom-blue-500">
                  {{ article.title }}
                </h3>
              </a>
              <span class="text-sm lg:text-base font-semibold">{{ diffFromNow(article.pubDate) }}</span>
              <p class="text-base lg:text-lg">
                {{ introText(article.description) }}
              </p>
              <a
                :href="article.link"
                target="_blank"
                class="text-gray-900 font-bold inline-block pt-1 mt-1"
              >
                <OpacityButton>
                  <Icon
                    name="right-arrow"
                    class="mr-2 text-sm"
                    solid
                  />
                  <span class="block pr-2">Läs mer här</span>
                </OpacityButton>
              </a>
            </div>
          </div>
          <div v-else>
            Inga nyheter funna
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAppStore } from '@/stores/app'
import { useNewsStore } from '@/stores/news'
import time from '@/js/helpers/time'

export default {
  name: 'Installer',
  setup () {
    const appStore = useAppStore()
    const newsStore = useNewsStore()

    return { appStore, newsStore }
  },
  computed: {
    ...mapState(useNewsStore,['news', 'loading']),
    newsLimited () {
      if(!this.news) {
        return []
      }

      if(this.news.length > 5) {
        return this.news.slice(0,5)
      }

      return this.news
    }
  },
  async created () {
    this.newsStore.fetchAction()
  },
  methods: {
    changeTest() {
      this.appStore.setTestAction("hello")
    },
    introText(text) {
      return text.split(' ', 15).join(' ') + '...';
    },
    diffFromNow (datetime) {
      if (datetime) return time.diffFromNow(datetime)
    }
  }
}
</script>
