<template>
  <div>
    <Login
      :email="email"
      :login-error="usersStore.loginError"
      :password="password"
      :native="isNative"
      @login="login"
      @email="email = $event.target.value"
      @password="password = $event.target.value"
      @go-back="goBack"
    />
  </div>
</template>

<script>

import Login from './_components/Login'
import router from '@/js/router'
import { useUsersStore } from '@/stores/users'

export default {
  name: 'LoginModule',
  
  components: {
    Login
  },
  setup () {
    const usersStore = useUsersStore()

    return { usersStore }
  },
  data () {
    return {
      email: '',
      password: ''
    }
  },
  beforeCreate () {
    if (this.usersStore.isLoggedIn || (this.usersStore.user)) {
      router.push({ name: 'home' })
    }
  },
  methods: {
    login () {
        this.usersStore.login({ email: this.email, password: this.password })
    },
    goBack () {
      if (window.history.length === 0) {
        this.$router.push({ name: 'home' })
      } else {
        this.$router.back()
      }
    }
  }
}
</script>
