<template>
  <Container wide>
    <h1 class="text-custom-gray-900 font-extrabold text-2xl z-30 flex items-center">
      Växelriktare / Inverter
    </h1>
    
    https://www.deyeinverter.com/download/product-manual/#product-2 - Fyra första

    <p class="mb-4">
      Vi samarbetar idag med flera av de ledande av växelriktare. Vi har ett nära samarbete med Deye Sun där vi själva CE-certifierat dessa maskiner på den svenska marknaden.
    </p>
    <p class="mb-4">
      Vi kan leverera allt ifrån en 1 kwh maskin upp till 50 kwh som är det perfekta valet vid större anläggningar.
    </p>
    <p class="mb-4">
      Alla våra växelriktare är dessutom IP65-klassade vilket gör att du kan montera de ute som inne. Alla maskiner har även ett trådlös AP med WIFI som gör att du kan följa din produktion i realtid över dator eller telefon.
    </p>
    <p class="mb-4">
      Vi kan även leverera en växelriktare av hybrid-modell för er som installerar batterier.
    </p>
  </Container>
</template>

<script>

export default {
  name: 'Inverter',
  props: {
  }
}
</script>
